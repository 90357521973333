@import url(https://fonts.googleapis.com/css2?family=Righteous&family=Russo+One&display=swap);
html,
body,
div,
span,
applet,
object,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@font-face {
  font-family: 'Russo One', sans-serif;
  src: local("coder"), url(/static/media/font.617a0a92.woff) format("woff");
}

body {
  margin: 0;
  font-family: 'Russo One', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
 font-family: 'Russo One', sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

.curs-fff {
  color: #ffffff;
}

div {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
}


@media only screen and (max-width: 650px) {
.displaynone {
  display: none;
}
.scsc {
  border-radius: 10px 10px 0px 0px !important;
}
.hidden-xs {
  display: none;
}
.mobile-ui {
  line-height: 0.6 !important;
  margin-top: 0 !important;
}
.mobile-logo {
  max-width:92px !important;
  width: 92px;
}
}

.mobile-logo {
max-width:92px !important;
width: 92px;
}

.clipped-txt {
  text-align: center;
  font-size: 43px;
  font-weight: bold;
  line-height: 1.4;
}

.connect-btns {
  margin-top: 1rem;
  background: linear-gradient(134deg,#EEFF00,#F54562,#FF00AA)!important;
  box-shadow: 0 8px 8px -4px rgba(245,69,98,.12),0 12px 32px rgba(245,69,98,.24),0 2px 4px -1px rgba(10,70,82,.12),0 0 1px rgba(245,69,98,.24)!important;
}

@media screen and (max-width: 670px) {
.connect-btns {
width: 100% !important;
    margin: 0px;
    max-width: 247px;
    min-height: 55px;
    margin-top: 10px;
}
.btn-secondary-connect {
  margin-top: 0px;
}
.disconnect-wallet-btn {
  margin-top: 1rem;
}
}
.minus {
font-size: 2rem !important;
margin-right: 1rem !important;
margin-top: -3px !important;
}

.plus {
  font-size: 2rem !important;
  margin-left: 1rem !important;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.connect-wallet-button2 {
  background: -webkit-linear-gradient(left, #a7d451, #15daec);
  background-size: 200% 200%;
  -webkit-animation: gradient-animation 4s ease infinite;
          animation: gradient-animation 4s ease infinite;
}







.text-align-center {
  text-align: center;
}

.max-width-large {
  width: 100%;
  max-width: 48rem;
}

.margin-bottom {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}
.margin-small {
  margin: 10px;
}

.heading {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}

@media screen and (min-width: 1280px) {
.heading-3 {
    font-size: 75px;
    font-weight: bolder !important;
}
}

@media screen and (max-width: 500px) {
.heading {
  margin-bottom: 0;
  font-size: 11px !important;
  font-weight: 500;
}
.heading-3 {
  font-size: 1.6rem !important;
  font-weight: bolder !important;
}
}

.heading-3 {
    font-size: 75px;
    font-weight: 900;
}

.bordertext {
  -webkit-text-fill-color: transparent !important;
  -webkit-text-stroke-width: 1px !important;
  -webkit-text-stroke-color: #ffffff !important;
}

.noss {
  outline-offset: 0px;
  outline: #fff 3px;
  font-style: normal;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.bold-text-2 {
  color: #6efaf3;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}
.max-width-large {
color: #ffffff;
}

.spann-txt {
  margin-right: 7px;
  color: rgba(255, 255, 255, 0.4);
}

.btn-connect {
  font-family: 'Russo One';
  background: white;
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 50px;
  color: #000000;
  border: 3px solid white;
  text-transform: uppercase;
  font-weight: 900 !important;
  min-height: 60px;
}

.btn-connect-2 {
  font-family: 'Russo One';
  background: white;
  margin-bottom: 10px;
  font-size: 20px;
  cursor: pointer;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 50px;
  color: #000000;
  border: 3px solid white;
  text-transform: uppercase;
  font-weight: 900 !important;
  min-height: 60px;
}




.border-l1 {
  display: grid;
  width: 100%;
  border-radius: 7px;
  grid-template-columns: repeat(2,minmax(0,1fr));
  min-width: 24rem;
  font-weight: 900;
  border: 3px solid #dfdfdf;
}
.L-price {
  text-align: center;
  padding-top: 10px;
  font-weight: 900;
  font-size: 16px;
  padding-bottom: 10px;
  border: 1px solid #ffffff;
}

.r-mintprice {
  text-align: center;
  font-weight: 900;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ffffff
}

.text-white\/40 {
  color: #fff6;
}
.uppercase {
  text-transform: uppercase;
}
.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-akira-expanded {
  font-weight: 900;
}

.pt-2 {
  padding-top: 0.5rem;
}


.okami-txt-h {
  text-align: center;
    font-size: 2.3rem;
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .body-100 {
    height: 100%;
  }
}
@media only screen and (min-width: 800px) {
.okami-txt-h {
  font-size: 5rem;
}
.body-100 {
  height: 100vh;
}
}
.web3modal-provider-name {
  font-weight: 100 !important;
}



.supply-2-3 {
  color: white;
}
.connect-wallet-ttxt {
  font-size: 0.7rem;
}

.scsc {
  width: 100% !important;
}

.gradientbox {
  padding: 0px;
  border-radius: 12px;
  box-shadow: 8px 8px 24px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%), 0 1px 1px rgb(9 13 20 / 40%);
  background: linear-gradient(135deg,rgba(59,62,66,.4) 0%,rgba(59,62,66,.4) 50%,rgba(33,36,41,.4) 75%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.gradientbox2 {
  padding: 10px;
  box-shadow: 8px 8px 24px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%), 0 1px 1px rgb(9 13 20 / 40%);
  background: linear-gradient(135deg,rgba(59,62,66,.4) 0%,rgba(59,62,66,.4) 50%,rgba(33,36,41,.4) 75%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.heading-31.h1-row-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.heading-31 {
  font-weight: 700;
  color: white;
}
h2 {
  font-size: 3rem;
  line-height: 1.2;
  letter-spacing: -.03em;
}
.paragraph-10 {
  margin-bottom: 0;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  line-height: 1.6;
}

.container {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.top-div {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  width: 100%;
  justify-content: space-between;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}
.nav-btn {
  font-size: 0.8rem;
    color: hsla(0,0%,100%,.9);
    padding: 10px;
    position: relative;
    margin-right: 10px !important;
}

a {
  text-decoration: none !important;
}

@media only screen and (min-width: 677px) {
.gradientbox {
  display: flex !important;
  max-width: 850px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.video-div {
  width: 43%;
  margin-right: auto;
}
.info-div {
  margin-right: auto;
  margin-left: auto;
  text-align-last: center;
  width: 57%;
}
.scsc {
  width: 100% !important;
  border-radius: 12px 0px 0px 12px !important;
}
}

@media only screen and (max-width: 676px) {
.info-div {
  text-align-last: center;
  padding-bottom: 18px;
}
.top-div {
  margin-bottom: 0px;
}
.heading-31.h1-row-1 {
  margin-top: 0.5rem;
  margin-bottom: 0px;
  text-align: center !important;
}
}

.nav-right {
  display: flex;
}

.after-txt:after {
  content: "new";
  position: absolute;
  font-size: 10px;
  top: -8px;
  font-family: monospace;
  border-radius: 5px;
  right: 4px;
  font-weight: 100;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  background-color: #b83848;
  color: white;
  padding-right: 4px;
}

.rounded-sep {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.socials-footer {
  text-align: center;
}

.row-footer {
  margin-top: 3rem;
    padding-top: 60px;
    padding-bottom: 15px;
    border-top: 1px solid #2b3036;
}

.socials-logos {
  max-width: 24px;
  min-width: 24px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;

}

.background-socials {
  height: 48px;
    max-width: 48px;
    min-width: 48px;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
}

.centerd-buttons {
  text-align: center !important;
}
.centered-btns-txt {
  text-align: center !important;
}
